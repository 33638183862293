import React from "react";
import { Card, Col } from "react-bootstrap";
import Iframe from 'react-iframe'

const Users = () => {
  return (
    <Iframe style="background: #F1F5F4;border: none;border-radius: 2px;box-shadow: 0 2px 10px 0 rgba(70, 76, 79, .2);display: initial;height: 100vh;" width="100%" height="850px"  src="https://charts.mongodb.com/charts-dml-project-zpjjy/embed/dashboards?id=6421e8c0-7649-487d-869e-edd2503452f2&theme=light&autoRefresh=true&maxDataAge=3600&showTitleAndDesc=false&scalingWidth=fixed&scalingHeight=fixed"></Iframe>
  );
};

export default Users;
