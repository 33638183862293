import React, { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import QRCode from "react-qr-code";
const ShowQR = ({ qrModal, setQrModal, qrId }) => {

  return (
    <div>
      <Modal className="fade " show={qrModal}>
        <Modal.Header>
          <Modal.Title>Event Source QR</Modal.Title>
          <Button
            onClick={() => setQrModal(false)}
            variant=""
            className="btn-close"
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center">
            {
              typeof qrId === 'string' ?
              <QRCode value={qrId} />
              :
              <QRCode value={`https://orientscanner.checktestwebsite.com/checkBadgeCode/${qrId}`} />
            }
          </div>
          <Button className="btn-warning btn-sm d-block mx-auto mt-3" onClick={()=> setQrModal(false)}>Close</Button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ShowQR;
