import React from "react";
import QRCode from "react-qr-code";
import { useParams } from "react-router-dom";

const BadgeCodePage = () => {
  const { badgeCode } = useParams();
  return (
    <div className="container">
      <div className="col-xl-12 col-lg-12 mt-5">
        <div className="card">
          <h3 className="text-center mt-2">Badge Code</h3>
          <div className="card-body">
            <div className="d-flex justify-content-center">
              <QRCode value={badgeCode} />
            </div>
            <p className="text-center mt-2">
              Badge Code: <strong>{badgeCode}</strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BadgeCodePage;
