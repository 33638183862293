import React, { useEffect, useState } from "react";
import axiosInstance from "../../services/AxiosInstance";
import { Col, Button } from "react-bootstrap";
import { IoNotificationsSharp,IoSendSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { InfinitySpin } from "react-loader-spinner";
import UpdateEventRegistration from "./UpdateEventRegistration";
import ShowQR from "../EventSources/ShowQR";
import FilteringTable from "../../jsx/components/FilteringTable/FilteringTable";
import { ColumnFilter } from "../../jsx/components/FilteringTable/ColumnFilter";
import CreateEventRegModal from "./CreateEventRegModal";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteAsyncReg,
  fetchAllRegistrations,
  getAllRegistrations,
} from "../../store/features/registrationSlice";
import axios from "axios";
import SyncGoogleModal from "./SyncGoogleModal";
import "./EventRegistrations.css";
import {format} from 'date-fns';
import moment from "moment";

const EventRegistration = () => {
  const navigate = useNavigate();
  // const [eventRegistration, setEventRegistration] = useState([]);
  const eventRegistrations = useSelector(getAllRegistrations);
  const [refetch, setRefetch] = useState(0);
  const [updatedModal, setUpdatedModal] = useState(false);
  const [selected, setSelected] = useState("");
  const [qrModal, setQrModal] = useState(false);
  const [qrId, setQrId] = useState("");
  const [showCreateModal, setShowCreateModal] = useState(false);
  const dispatch = useDispatch();
  const [showSyncModal, setShowSyncModal] = useState(false);
  const columns = [
    {
      Header: "Date of Reg",
      Footer: "Date of Reg",
      accessor: "createdDate",
      // cell: props => {moment(props.getValue()).format('DD MM YYYY')} ,
      // accessor: props => {moment(props.createdDate).format('YYYY MM DD')} ,
      // columnHelper.accessor('createdDate', {
      Cell: ({ value }) => {return moment(new Date(value)).format('DD/MM/YYYY')},
      // }),
      Filter: ColumnFilter,
    },
    {
      Header: "Event Name",
      Footer: "Event Name",
      accessor: "event.eventName",
      Filter: ColumnFilter,
    },
    {
      Header: "Source Name",
      Footer: "Source Name",
      accessor: "source.source",
      Filter: ColumnFilter,
    },
    {
      Header: "Name",
      Footer: "Name",
      accessor: "regName",
      Filter: ColumnFilter,
    },
    {
      Header: "Email",
      Footer: "Email",
      accessor: "regEmail",
      Filter: ColumnFilter,
    },
    {
      Header: "Phone",
      Footer: "Phone",
      accessor: "regPhone",
      Filter: ColumnFilter,
    },
    {
      Header: "Country",
      Footer: "Country",
      accessor: "regCountry",
      Filter: ColumnFilter,
    },
    {
      Header: "Year",
      Footer: "Year",
      accessor: "regYear",
      Filter: ColumnFilter,
    },
    {
      Header: "Check QR",
      Footer: "Check QR",
      Filter: ColumnFilter,
      accessor: "badgeCode",
      Cell: ({ row }) => (
        <button
          className="btn btn-primary btn-xs"
          onClick={() => {
            setQrId(row.original.badgeCode);
            setQrModal(true);
          }}
        >
          See QR
        </button>
      ),
    },
    {
      Header: "Register",
      Footer: "Register",
      Filter: ColumnFilter,
      accessor: "",
      Cell: ({ row }) => (
        <button
          className="btn btn-primary btn-xs"
          onClick={() => navigate(`/checkBadgeCode/${row.original.badgeCode}`)}
        >
          Visit
        </button>
      ),
    },
    {
      Header: "Send SMS",
      Footer: "Send SMS",
      accessor: "",
      Filter: ColumnFilter,
      Cell: ({ row }) => (
        <IoSendSharp
          className="notification-icons"
          onClick={() => handleNotify(row.original._id)}
        />
      ),
    },
    {
      Header: "Attendance",
      Footer: "Attendance",
      accessor: "attended",
      Filter: ColumnFilter,
      Cell: ({ row }) => (
        <>{row.original.attended === true ? <>True</> : <>False</>}</>
      ),
    },
    {
      Header: "Status",
      Footer: "Status",
      Filter: ColumnFilter,
      accessor: "_id",
      Cell: ({ row }) => (
        <>
          <button
            href="#"
            className="btn btn-primary shadow btn-xs sharp me-1"
            onClick={() => {
              setSelected(row.original._id);
              setUpdatedModal(true);
            }}
          >
            <i className="fa fa-pencil"></i>
          </button>
          <button
            href="#"
            onClick={() => handleDeleteEvent(row.original._id)}
            className="btn btn-danger shadow btn-xs sharp"
          >
            <i className="fa fa-trash"></i>
          </button>
        </>
      ),
    },
  ];

  useEffect(() => {
    dispatch(fetchAllRegistrations());
  }, [dispatch, refetch]);

  const handleDeleteEvent = (id) => {
    dispatch(deleteAsyncReg(id)).then((res) => {
      if (res.payload.type === 1) {
        setRefetch(refetch + 1);
      }
    });
  };

  const handleNotify = async (id) => {
    await axiosInstance
      .post("/registration/sendRegDetails", { _id: id })

      .then((res) => {
        //console.log(res);
        if (res.data.type === 1) {
          toast.info("Successfully Notified!");
        } else {
          toast.error("Failed to send Notification!");
        }
      });
  };
  // console.log("Hello")
  return (
    <div className="event-container">
      <Col lg={12}>
        {eventRegistrations?.length === 0 ? (
          <div className="d-flex justify-content-center align-items-center pb-5 pt-5">
            <InfinitySpin width="200" color="#5bcfc5" />
          </div>
        ) : (
          <>
            <FilteringTable
              data={eventRegistrations?.data}
              columns={columns}
              title={"Event Registration"}
      
              
            />
          </>
        )}
      </Col>
      <ShowQR qrModal={qrModal} setQrModal={setQrModal} qrId={qrId}></ShowQR>
      <UpdateEventRegistration
        refetch={refetch}
        setRefetch={setRefetch}
        updatedModal={updatedModal}
        setUpdatedModal={setUpdatedModal}
        id={selected}
      ></UpdateEventRegistration>
      <CreateEventRegModal
        refetch={refetch}
        setRefetch={setRefetch}
        showCreateModal={showCreateModal}
        setShowCreateModal={setShowCreateModal}
      ></CreateEventRegModal>
      <SyncGoogleModal
        showSyncModal={showSyncModal}
        setShowSyncModal={setShowSyncModal}
      ></SyncGoogleModal>
      <Button
        onClick={() => setShowCreateModal(true)}
        className="d-block btn-xs create-btn"
      >
        Create Event
      </Button>
      <button
        onClick={() => setShowSyncModal(true)}
        className="btn btn-primary d-block btn-xs sync-btn"
      >
        Sync to google
      </button>
    </div>
  );
};

export default EventRegistration;
