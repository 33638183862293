import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import axiosInstance from "../../services/AxiosInstance";
import { useDispatch } from "react-redux";
import { addAsyncSource } from "../../store/features/sourceSlice";

const CreateEventSource = ({ eventSourceCreate, setEventSourceCreate, refetch, setRefetch }) => {
  const [source, setSource] = useState("");
  const [eventObjId, setEventObjId] = useState("");
  const [events, setEvents] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    axiosInstance.post("/events/read").then((res) => {
      if (res.data.type === 1) {
        setEvents(res.data.data);
        setEventObjId(res.data?.data[0]?._id)
      }
    });
  }, [refetch]);
  const handleCreateEvent = async (e) => {
    e.preventDefault();

    const data = {
      source,
      eventObjId,
    };
  dispatch(addAsyncSource(data)).then(res => {
    if(res.payload.type === 1){
      setEventSourceCreate(false)
      setRefetch(refetch+1)
    }
  })
  };

  return (
    <div>
      <Modal className="fade" show={eventSourceCreate}>
        <Modal.Header>
          <Modal.Title>Create Event</Modal.Title>
          <Button
            onClick={() => setEventSourceCreate(false)}
            variant=""
            className="btn-close"
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div className="col-xl-12 col-lg-12">
            <div className="basic-form">
              <form onSubmit={handleCreateEvent}>
                <div className="row">
                  <div className="form-group mb-3 col-md-12">
                    <label>Select Event</label>
                    <select
                      defaultValue={"option"}
                      className="form-control"
                      value={eventObjId}
                      onChange={(e) => setEventObjId(e.target.value)}
                    >
                      {events?.map((e) => (
                        <option key={e._id} value={e._id}>{e.eventName}</option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group mb-3 col-md-12">
                    <label>Source Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Source Name"
                      value={source}
                      onChange={(e) => setSource(e.target.value)}
                      required
                    />
                  </div>
                </div>

                <button
                  type="submit"
                  className="btn btn-primary d-block mx-auto"
                >
                  Create
                </button>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CreateEventSource;
