import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../services/AxiosInstance";
import { toast } from "react-toastify";

export const fetchAllSources = createAsyncThunk(
  "/source/fetchAllSources",
  async () => {
    const response = await axiosInstance.post("/source/read");
    //console.log(response);
    return response.data;
  }
);

export const addAsyncSource = createAsyncThunk(
  "source/addAsyncSource",
  async (data) => {
    // //console.log(payload)
    const response = await axiosInstance.post(`/source/save`, data);
    //console.log(response);
    if (response.data.type === 1) {
      toast.info("Source Created Successfully!");
    } else if (response.data.type === 2) {
      toast.error(response.data.message);
    }

    return response.data;
  }
);
export const deleteAsyncSource = createAsyncThunk(
  "source/deleteAsyncSource",
  async (id) => {
    // //console.log(payload)
    const response = await axiosInstance.post(`/source/delete`, { _id: id });
    //console.log(response);
    if (response.data.type === 1) {
      toast.warning("Source Deleted Successfully!");
    } else if (response.data.type === 2) {
      toast.error("Failed to Delete the Source!");
      // setRefetch(refetch + 1);
    }
    return response.data;
  }
);

export const updateAsyncSource = createAsyncThunk(
  "source/updateAsyncSource",
  async (data) => {
    // //console.log(payload)
    const response = await axiosInstance.post(`/source/update`, data);
    //console.log(response);
    if (response.data.type === 1) {
      toast.info("Source Updated Successfully!");
    } else {
      toast.error("Failed to update the Source!");
    }
    return response.data;
  }
);

export const getAsyncSourceById = createAsyncThunk(
  "source/getAsyncSourceById",
  async (selected) => {
    // //console.log(payload)
    const response = await axiosInstance.post("/source/read", {
      _id: selected,
    });
    //console.log(response);
    return response.data;
  }
);

const initialState = {
  allSources: [],
  addSource: [],
  sourceById: [],
  deleteSource: [],
  updateSource: [],
};

const sourceSlice = createSlice({
  name: "sources",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllSources.pending, () => {
      //console.log("pending");
    });
    builder.addCase(fetchAllSources.fulfilled, (state, { payload }) => {
      return { ...state, allSources: payload };
    });
    builder.addCase(fetchAllSources.rejected, () => {
      //console.log("rejected");
    });
    builder.addCase(addAsyncSource.pending, () => {
      //console.log("pending");
    });
    builder.addCase(addAsyncSource.fulfilled, (state, { payload }) => {
      return { ...state, addSource: payload };
    });
    builder.addCase(addAsyncSource.rejected, () => {
      //console.log("rejected");
    });
    builder.addCase(deleteAsyncSource.pending, () => {
        //console.log("pending");
      });
      builder.addCase(deleteAsyncSource.fulfilled, (state, { payload }) => {
        return { ...state, deleteSource: payload };
      });
      builder.addCase(deleteAsyncSource.rejected, () => {
        //console.log("rejected");
      });
      builder.addCase(updateAsyncSource.pending, () => {
        //console.log("pending");
      });
      builder.addCase(updateAsyncSource.fulfilled, (state, { payload }) => {
        return { ...state, updateSource: payload };
      });
      builder.addCase(updateAsyncSource.rejected, () => {
        //console.log("rejected");
      });
      builder.addCase(getAsyncSourceById.pending, () => {
        //console.log("pending");
      });
      builder.addCase(getAsyncSourceById.fulfilled, (state, { payload }) => {
        return { ...state, sourceById: payload };
      });
      builder.addCase(getAsyncSourceById.rejected, () => {
        //console.log("rejected");
      });
  },
});

export const getAllSources = (state) => state.source.allSources;
export const getAddSource = (state) => state.source.addSource;
export const getDeleteSource = (state) => state.source.deleteSource;
export const getUpdateSource = (state) => state.source.updateSource;
export const getSourceById = (state) => state.source.sourceById;
export default sourceSlice.reducer;
//console.log("Hello", sourceSlice.reducer);
