import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../services/AxiosInstance";
import { toast } from "react-toastify";

export const fetchAllEvents = createAsyncThunk(
  "/events/fetchAllEvents",
  async () => {
    const response = await axiosInstance.post("/events/read");
    //console.log(response);
    return response.data;
  }
);
export const addAsyncEvent = createAsyncThunk(
  "/events/addAsyncEvent",
  async (data) => {
    // //console.log(payload)
    const response = await axiosInstance.post(`/events/save`, data);
    //console.log(response);
    if (response.data.type === 1) {
      toast.info("Event Created Successfully!");
    } else if (response.data.type === 2) {
      toast.error(response.data.message);
    }

    return response.data;
  }
);
export const deleteAsyncEvent = createAsyncThunk(
  "/events/deleteAsyncEvent",
  async (id) => {
    // //console.log(payload)
    const response = await axiosInstance.post(`/events/delete`, { _id: id });
    //console.log(response);
    if (response.data.type === 1) {
      toast.warning("Event Deleted Successfully!");
    } else if (response.data.type === 2) {
      toast.error("Failed to Delete the Event!");
      // setRefetch(refetch + 1);
    }
    return response.data;
  }
);

export const updateAsyncEvent = createAsyncThunk(
  "/events/updateAsyncEvent",
  async (data) => {
    // //console.log(payload)
    const response = await axiosInstance.post(`/events/update`, data);
    //console.log(response);
    if (response.data.type === 1) {
      toast.info("Event Updated Successfully!");
    } else {
      toast.error("Failed to update the Event!");
    }
    return response.data;
  }
);

export const getAsyncEventById = createAsyncThunk(
  "/events/getAsyncEventById",
  async (selected) => {
    // //console.log(payload)
    const response = await axiosInstance.post("/events/read", {
      _id: selected,
    });
    //console.log(response);
    return response.data;
  }
);

const initialState = {
  allEvents: [],
  addEvent: [],
  eventById: [],
  deleteEvent: [],
  updateEvent: [],
};

const eventSlice = createSlice({
  name: "events",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllEvents.pending, () => {
      //console.log("pending");
    });
    builder.addCase(fetchAllEvents.fulfilled, (state, { payload }) => {
      return { ...state, allEvents: payload };
    });
    builder.addCase(fetchAllEvents.rejected, () => {
      //console.log("rejected");
    });
    builder.addCase(addAsyncEvent.pending, () => {
      //console.log("pending");
    });
    builder.addCase(addAsyncEvent.fulfilled, (state, { payload }) => {
      return { ...state, addEvent: payload };
    });
    builder.addCase(addAsyncEvent.rejected, () => {
      //console.log("rejected");
    });
    builder.addCase(deleteAsyncEvent.pending, () => {
      //console.log("pending");
    });
    builder.addCase(deleteAsyncEvent.fulfilled, (state, { payload }) => {
      return { ...state, deleteEvent: payload };
    });
    builder.addCase(deleteAsyncEvent.rejected, () => {
      //console.log("rejected");
    });
    builder.addCase(updateAsyncEvent.pending, () => {
      //console.log("pending");
    });
    builder.addCase(updateAsyncEvent.fulfilled, (state, { payload }) => {
      return { ...state, updateEvent: payload };
    });
    builder.addCase(updateAsyncEvent.rejected, () => {
      //console.log("rejected");
    });
    builder.addCase(getAsyncEventById.pending, () => {
      //console.log("pending");
    });
    builder.addCase(getAsyncEventById.fulfilled, (state, { payload }) => {
      return { ...state, eventById: payload };
    });
    builder.addCase(getAsyncEventById.rejected, () => {
      //console.log("rejected");
    });
  },
});

export const getAllEvents = (state) => state.event.allEvents;
// export const getAddEvent = (state) => state.event.addEvent;
export const getDeleteEvent = (state) => state.event.deleteEvent;
export const getUpdateEvent = (state) => state.event.updateEvent;
export const getEventById = (state) => state.event.eventById;

export default eventSlice.reducer;
// //console.log("Hello", eventSlice.reducer);
