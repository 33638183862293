import axios from "axios";
import { store } from "../store/store";

const axiosInstance = axios.create({
  baseURL: `http://65.1.81.5:4000/`,
});

// axiosInstance.interceptors.request.use((config) => {
//   const state = store.getState();
//   const token = state.auth.auth.idToken;
//   config.params = config.params || {};
//   config = { headers: { 'Authorization': token } };
//   return config;
// });

axiosInstance.interceptors.request.use(config => {
    const state = store.getState();
  const token = state.auth.auth.idToken;
    config.headers.Authorization = `${token}`;
    return config;
  });

export default axiosInstance;
