import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import ReactFlagsSelect from "react-flags-select";
import Papa from "papaparse";
import axiosInstance from "../../services/AxiosInstance";
import { addAsyncReg } from "../../store/features/registrationSlice";
import { Link } from "react-router-dom";
import pdfFile from "../../files/HowtoSyncFromGoogleSheet.pdf";
import "./EventRegistrations.css";
import { CSVLink } from "react-csv";

const SyncGoogleModal = ({
  refetch,
  setRefetch,
  setShowSyncModal,
  showSyncModal,
}) => {
  const [events, setEvents] = useState("");
  const [sources, setSources] = useState("");
  const [event, setEvent] = useState("");
  const [source, setSource] = useState("");
  const [name, setName] = useState("");
  const dispatch = useDispatch();
  const [count, setCount] = useState(0);
  const [counter, setCounter] = useState(0);
  const [csvFile, setCSVFile] = useState([]);
  useEffect(() => {
    axiosInstance.post("/events/readForDll").then((res) => {
      //console.log(res);
      if (res.data.type === 1) {
        setEvents(res.data.data);
        setEvent(res.data?.data[0]._id);
      }
    });
    axiosInstance.post("/source/readBySource").then((res) => {
      if (res.data.type === 1) {
        setSources(res.data.data);
        setSource(res.data?.data[0]?._id);
      }
    });
  }, []);
  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = pdfFile;
    link.download = "How-to-Sync-From-Google-Sheet.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleCreateEvent = async (e) => {
    debugger
    e.preventDefault();
    // console.log("Hello");
    const dataArray = [];
    setCount(0);
    setCounter(0);
    Papa.parse(name, {
      download: true,
      header: true,
      complete: async (results) => {
        // console.log(results);
        const filtertedData = results?.data?.filter(
          (d) => d.Status.toLowerCase() === "false"
        );
        //  setCSVFile(filtertedData)
        setCount(filtertedData.length);
        let counterLoop = 0;
        for (const i of filtertedData) {
          const data = {
            eventObjId: event,
            sourceObjId: source,
            regName: i.Name,
            regCountry: i.Country.slice(0, 2).toUpperCase(),
            regYear: i.Year,
            regPhone: i.Phone,
            regEmail: i.Email,
          };
          debugger
          await dispatch(addAsyncReg(data)).then((res) => {
            // debugger
            if (res.payload.type === 1) {
              counterLoop = counterLoop + 1;
              i.Status="TRUE";
              dataArray.push(i);
            }else{
              i.Status=res.payload.message;
              dataArray.push(i);
            }
          });
        }
        setCounter(counterLoop);
        setCSVFile(dataArray);
        // console.log(dataArray)
      },
    });
  };
  // console.log(csvFile);
  return (
    <div>
      <Modal className="fade" show={showSyncModal}>
        <Modal.Header>
          <Modal.Title>Create Event</Modal.Title>
          <Button
            onClick={() => setShowSyncModal(false)}
            variant=""
            className="btn-close"
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleCreateEvent}>
            <div className="row">
              <div className="form-group mb-3 col-md-6">
                <label>Select Event</label>
                <select
                  id="inputState"
                  className="form-control"
                  onChange={(e) => setEvent(e.target.value)}
                  required
                >
                  {events &&
                    events?.map((event) => (
                      <option key={event._id} value={event._id}>
                        {event.eventName}
                      </option>
                    ))}
                </select>
              </div>

              <div className="form-group mb-3 col-md-6">
                <label>Select Source</label>
                <select
                  defaultValue={"option"}
                  id="inputState"
                  className="form-control"
                  required
                  value={source}
                  onChange={(e) => setSource(e.target.value)}
                >
                  {sources &&
                    sources?.map((source) => (
                      <option key={source._id} value={source._id}>
                        {source.source}
                      </option>
                    ))}
                </select>
              </div>

              <div className="form-group mb-3 col-md-12">
                <label>Public URL</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Public URL"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>
            <p className="text-center">
              <small>
                <b>{counter}</b> items uploaded out of <b>{count}</b>
              </small>
            </p>

            {csvFile.length !== 0 && (
              <button className="btn btn-primary btn-sm d-block mx-auto mb-2">
                <CSVLink className="text-white" data={csvFile}>
                  Download Report
                </CSVLink>
              </button>
            )}

            <button type="submit" className="btn btn-primary d-block mx-auto">
              Create
            </button>
          </form>
          <p className="download-style" onClick={handleDownload}>
            How to Sync From Google Sheet?
          </p>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SyncGoogleModal;
