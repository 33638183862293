import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from '../../services/AxiosInstance'
import { toast } from 'react-toastify';


export const fetchAllRegistrations = createAsyncThunk('/registrations/fetchAllRegistrations', async () =>{
    const response = await axiosInstance.post("/registration/read");
    //console.log(response)
    return response.data;
})
export const addAsyncReg = createAsyncThunk(
  "registrations/addAsyncReg",
  async (data) => {
    // //console.log(payload)
    const response = await axiosInstance.post(`/registration/save`, data);
    //console.log(response);
    if (response.data.type === 1) {
      toast.info("Event Registered Successfully!");
    } else if (response.data.type === 2) {
      toast.error(response.data.message);
    }

    return response.data;
  }
);

export const deleteAsyncReg = createAsyncThunk(
  "registrations/deleteAsyncReg",
  async (id) => {
    // //console.log(payload)
    const response = await axiosInstance.post(`/registration/delete`, { _id: id });
    //console.log(response);
    if (response.data.type === 1) {
      toast.warning("Registration Deleted Successfully!");
    } else if (response.data.type === 2) {
      toast.error("Failed to Delete the Registration!");
      // setRefetch(refetch + 1);
    }
    return response.data;
  }
);

export const fetchRegById = createAsyncThunk(
  "registrations/fetchRegById",
  async (selected) => {
    // //console.log(payload)
    const response = await axiosInstance.post("/registration/read", {
      _id: selected,
    });
    //console.log(response);
    return response.data;
  }
);

export const updateAsyncReg = createAsyncThunk(
    "registrations/updateAsyncReg",
    async (data) => {
      // //console.log(payload)
      const response = await axiosInstance.post(`/registration/update`, data);
      //console.log(response);
      if (response.data.type === 1) {
        toast.info("Registration Updated Successfully!");
      } else {
        toast.error("Failed to update the Registration!");
      }
      return response.data;
    }
  );

const initialState = {
    allRegistrations: [],
    addReg: [],
    deleteReg:[],
    regById:[],
    updateReg:[]
}

const registrationSlice = createSlice({
    name:'registrations',
    initialState,
    reducers:{},
    extraReducers: (builder) =>{
        builder.addCase(fetchAllRegistrations.pending, ()=>{
            //console.log("pending")
        } )
        builder.addCase(addAsyncReg.fulfilled, (state, {payload})=>{
            return {...state, addReg: payload}
        } )
        builder.addCase(fetchAllRegistrations.fulfilled, (state, {payload})=>{
            return {...state, allRegistrations: payload}
        } )
         builder.addCase(deleteAsyncReg.fulfilled, (state, {payload})=>{
            return {...state, deleteReg: payload}
        } )
        builder.addCase(fetchRegById.fulfilled, (state, {payload})=>{
            return {...state, regById: payload}
        } )
        builder.addCase(updateAsyncReg.fulfilled, (state, {payload})=>{
            return {...state, updateReg: payload}
        } )
        builder.addCase(fetchAllRegistrations.rejected, ()=>{
            //console.log("rejected")
        } )
    }
    // extraReducers:{
    //     [fetchAllRegistrations.fulfilled]: (state, { payload }) => {
    //         //console.log("data fetched successfully");
    //         return { ...state, allRegistrations: payload };
    //       },
    // }
})

export const getAllRegistrations = (state) => state.registration.allRegistrations
export const getAddRegistration = (state) => state.registration.addReg;
export const getDeleteRegistration = (state) => state.registration.deleteReg;


export default registrationSlice.reducer
//console.log("Hello",registrationSlice.reducer)