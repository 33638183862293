
import { configureStore } from '@reduxjs/toolkit'
import { AuthReducer } from './reducers/AuthReducer';

// import eventReducer  from './features/eventSlice'
import sourceReducer from './features/sourceSlice';
import registrationReducer from './features/registrationSlice';
import programReducer from './features/programSlice';

export const store = configureStore({
    reducer:{
        auth: AuthReducer,
        // event: eventReducer,
        source: sourceReducer,
        registration: registrationReducer, 
        program: programReducer
       }
})
