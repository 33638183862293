import {
  formatError,
  login,
  runLogoutTimer,
  saveTokenInLocalStorage,
  signUp,
} from "../../services/AuthService";

export const SIGNUP_CONFIRMED_ACTION = "[signup action] confirmed signup";
export const SIGNUP_FAILED_ACTION = "[signup action] failed signup";
export const LOGIN_CONFIRMED_ACTION = "[login action] confirmed login";
export const LOGIN_FAILED_ACTION = "[login action] failed login";
export const LOADING_TOGGLE_ACTION = "[Loading action] toggle loading";
export const LOGOUT_ACTION = "[Logout action] logout action";

export function signupAction(email, password, history) {
  return (dispatch) => {
    signUp(email, password)
      .then((response) => {
        saveTokenInLocalStorage(response.data);
        runLogoutTimer(dispatch, response.data.expiresIn * 1000, history);
        dispatch(confirmedSignupAction(response.data));
        history.push("/dashboard");
      })
      .catch((error) => {
        const errorMessage = formatError(error.response.data);
        dispatch(signupFailedAction(errorMessage));
      });
  };
}

export function logout(dispatch) {
  localStorage.removeItem("userDetails");
  dispatch(loginConfirmedAction({}))
  // history.push("/");
  return {
    type: LOGOUT_ACTION,
  };
}

export function loginAction(email, password, navigate) {
  return (dispatch) => {
    login(email, password)
      .then((response) => {
        //console.log(response.data);
        if (response.data.type === 2) {
            //console.log('Hello')
        //   const errorMessage = formatError(response?.data?.message);
          dispatch(loginFailedAction(response?.data?.message));
        } else {
          saveTokenInLocalStorage(response.data.data[0]);
          // runLogoutTimer(
          //     dispatch,
          //     response.data.expiresIn * 100000,
          //     history,
          // );
          const data = {
            email: response.data.data[0].email,
            idToken: response.data.data[0].r,
            localId: response.data.data[0].r,
            expiresIn: response.data.data[0].r,
            refreshToken: response.data.data[0].r,
            role: response.data.data[0].roleCode,
            profilename: response.data.data[0].profilename,
          };
          dispatch(loginConfirmedAction(data));
          if(response.data.data[0].roleCode === '676dza2'){
            navigate("/dashboard/users");
          }
          if(response.data.data[0].roleCode === '34fs3'){
            navigate("/dashboard/events");
          }
        }
      })
    //   .catch((error) => {
    //     ////console.log(error);
    //     const errorMessage = formatError(error.response.data);
    //     dispatch(loginFailedAction(errorMessage));
    //   });
  };
}

export function loginFailedAction(data) {
  return {
    type: LOGIN_FAILED_ACTION,
    payload: data,
  };
}

export function loginConfirmedAction(data) {
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: data,
  };
}

export function confirmedSignupAction(payload) {
  return {
    type: SIGNUP_CONFIRMED_ACTION,
    payload,
  };
}

export function signupFailedAction(message) {
  return {
    type: SIGNUP_FAILED_ACTION,
    payload: message,
  };
}

export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status,
  };
}
