import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import axiosInstance from "../../services/AxiosInstance";
import ReactFlagsSelect from "react-flags-select";
import { addAsyncReg } from "../../store/features/registrationSlice";
import { useDispatch } from "react-redux";

const CreateEventRegModal = ({
  refetch,
  setRefetch,
  setShowCreateModal,
  showCreateModal,
}) => {
  const [selected, setSelected] = useState("");
  const [events, setEvents] = useState("");
  const [sources, setSources] = useState("");
  const [event, setEvent] = useState("");
  const [source, setSource] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [year, setYear] = useState("2023");
  const [emailError, setEmailError] = useState("2023");
  const [phoneError, setPhoneError] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    axiosInstance.post("/events/readForDll").then((res) => {
      //console.log(res);
      if (res.data.type === 1) {
        setEvents(res.data.data);
        setEvent(res.data?.data[0]._id);
      }
    });
  }, []);
  useEffect(() => {
    if (event) {
      axiosInstance
        .post("/source/readSourceByEvent", { eventObjId: event })
        .then((res) => {
          if (res.data.type === 1) {
            setSources(res.data.data);
            setSource(res.data?.data[0]?._id);
          }
        });
    }
  }, [event]);
  const handleCreateEvent = async (e) => {
    e.preventDefault();
    const data = {
      eventObjId: event,
      sourceObjId: source,
      regName: name,
      regCountry: selected,
      regYear: year,
      regPhone: phone,
      regEmail: email,
    };
    //console.log(data);
    const regex = /^[0-9]*$/;
    const validation = /^[+]?[0-9]{0,3}[\\s]?[6-9][0-9]{9}$/;
    //console.log("Hello");
    if (!regex.test(phone)) {
      e.target.value = phone.replace(/[^\d]/g, "");
    } else if (validation.test(phone)) {
      setPhoneError("");
      //dispatched data
      const data = {
        eventObjId: event,
        sourceObjId: source,
        regName: name,
        regCountry: selected,
        regYear: year,
        regPhone: phone,
        regEmail: email,
      };
      //console.log(data);
      dispatch(addAsyncReg(data)).then((res) => {
        if (res.payload.type === 1) {
          setShowCreateModal(false);
        }
      });
    } else {
      setPhoneError("Invalid Number");
    }
  };

  const handleEvent = async (e) => {
    setEvent(e.target.value);
    await axiosInstance
      .post("/source/readSourceByEvent", { eventObjId: e.target.value })
      .then((res) => {
        if (res.data.type === 1) {
          setSources(res.data.data);
        }
      });
  };
  return (
    <div>
      <Modal className="fade" show={showCreateModal}>
        <Modal.Header>
          <Modal.Title>Create Event</Modal.Title>
          <Button
            onClick={() => setShowCreateModal(false)}
            variant=""
            className="btn-close"
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleCreateEvent}>
            <div className="row">
              <div className="form-group mb-3 col-md-6">
                <label>Select Event</label>
                <select
                  id="inputState"
                  className="form-control"
                  onChange={handleEvent}
                  required
                >
                  {events &&
                    events?.map((event) => (
                      <option key={event._id} value={event._id}>
                        {event.eventName}
                      </option>
                    ))}
                </select>
              </div>

              <div className="form-group mb-3 col-md-6">
                <label>Select Source</label>
                <select
                  defaultValue={"option"}
                  id="inputState"
                  className="form-control"
                  required
                  value={source}
                  onChange={(e) => setSource(e.target.value)}
                  // aria-readonly
                >
                  {sources &&
                    sources?.map((source) => (
                      <option key={source._id} value={source._id}>
                        {source.source}
                      </option>
                    ))}
                </select>
              </div>

              <div className="form-group mb-3 col-md-6">
                <label>Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="name"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              <div className="form-group mb-3 col-md-6">
                <label>Email</label>
                <input
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  className="form-control"
                  placeholder="Email"
                  value={email}
                  required
                />
                {emailError && <small>{emailError}</small>}
              </div>

              <div className="form-group mb-3 col-md-6">
                <label>Phone</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Phone"
                  required
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
                {phoneError && (
                  <small className="text-danger">{phoneError}</small>
                )}
              </div>
              <div className="form-group mb-3 col-md-6">
                <label>Country</label>
                <ReactFlagsSelect
                countries={["AU", "GB", "US", "DE", "AU", "CA", "AE","FR","IE","SE","CH","SG"]}
                  selected={selected}
                  onSelect={(code) => setSelected(code)}
                  searchable
                  searchPlaceholder="Select a country"
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group mb-3 col-md-6">
                <label>Select Year</label>
                <select
                  defaultValue={year}
                  id="inputState"
                  className="form-control"
                  onChange={(e) => setYear(e.target.value)}
                >
                  <option>2023</option>
                  <option>2024</option>
                  <option>2025</option>
                  <option>Later</option>
                </select>
              </div>
            </div>

            <button type="submit" className="btn btn-primary d-block mx-auto">
              Create
            </button>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CreateEventRegModal;
