import {  useEffect } from "react";

/// Components
import Index from "./jsx";
import {useDispatch } from "react-redux";

// action
import { checkAutoLogin } from "./services/AuthService";
/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Route, Routes, useNavigate } from "react-router-dom";
import Users from "./CustomPages/Users/Users";
import Events from "./CustomPages/Events/Events";
import EventSources from "./CustomPages/EventSources/EventSources";
import EventRegistration from "./CustomPages/EventRegistrations/EventRegistrations";

import Login from './jsx/pages/Login';
import Register from "./jsx/pages/Registration";
import EventDetails from "./CustomPages/EventDetails/EventDetails";
import BadgeCodePage from "./CustomPages/BadgeCodePage/BadgeCodePage";
import RequireAuth from "./jsx/components/Authentication/RequireAuth";
import Error404 from "./jsx/pages/Error404";
function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  useEffect(() => {
    checkAutoLogin(dispatch, navigate);
  }, [dispatch]);

  return (
    <div>
      {/* <Suspense  fallback={<div>Loading...</div>}> */}
      <Routes>
        <Route path="/" element={<Login></Login>} />
        <Route path="/page-register" element={<Register />} />
        <Route path="/registerbysource/:id" element={<EventDetails />} />
        <Route path="/checkBadgeCode/:badgeCode" element={<BadgeCodePage />} />
        <Route path="/dashboard" element={<RequireAuth><Index></Index></RequireAuth>}>
            <Route index path="/dashboard/users" element={<Users />}></Route>
            <Route path="/dashboard/events" element={<Events />}></Route>
            <Route path="/dashboard/event-sources" element={<EventSources />}></Route>
            <Route path="/dashboard/event-registrations" element={<EventRegistration />}></Route>
        </Route>
        {/* <Route path="/page-forgot-password" element={<ForgotPassword />} /> */}
        <Route path="*" element={<Error404 />} />
      </Routes>
      {/* </Suspense> */}
      <ToastContainer />
    </div>
  );
}

export default App;
