import React, { useEffect, useState } from "react";
import {
  Col,
  Button,
} from "react-bootstrap";
import CreateEvent from "./CreateEvent";
import UpdateEvent from "./UpdateEvent";
import { InfinitySpin } from "react-loader-spinner";
import { ColumnFilter } from "../../jsx/components/FilteringTable/ColumnFilter";
import FilteringTable from "../../jsx/components/FilteringTable/FilteringTable";
import { useDispatch, useSelector } from "react-redux";
import { deleteAsyncProgram, fetchAllPrograms, getAllPrograms } from "../../store/features/programSlice";
const Events = () => {
  // const [events, setEvents] = useState([]);
  const events = useSelector(getAllPrograms);
  const [modalCentered, setModalCentered] = useState(false);
  const [refetch, setRefetch] = useState(0);
  const [updatedModal, setUpdatedModal] = useState(false);
  const [selected, setSelected] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAllPrograms());
  }, [dispatch, refetch]);

  const columns = [
    {
      Header: "Name Of the Event",
      Footer: "Name Of the Event",
      accessor: "eventName",
      Filter: ColumnFilter,
    },
    {
      Header: "Event Start Date",
      Footer: "Event Start Date",
      accessor: "startDate",
      Filter: ColumnFilter,
    },
    {
      Header: "Event End Date",
      Footer: "Event End Date",
      accessor: "endDate",
      Filter: ColumnFilter,
    },
    {
      Header: "Status",
      Footer: "Status",
      Filter: ColumnFilter,
      accessor: "_id",
      Cell: ({ row }) => (
        <>
          <button
            href="#"
            className="btn btn-primary shadow btn-xs sharp me-1"
            onClick={() => {
              setSelected(row.original._id);
              setUpdatedModal(true);
            }}
          >
            <i className="fa fa-pencil"></i>
          </button>
          <button
            href="#"
            onClick={() => handleDeleteEvent(row.original._id)}
            className="btn btn-danger shadow btn-xs sharp ms-3"
          >
            <i className="fa fa-trash"></i>
          </button>
        </>
      ),
    },
  ];

  //console.log(events);

  const handleDeleteEvent =(id) => {
    dispatch(deleteAsyncProgram(id)).then((res) => {
      if (res.payload.type === 1) {
        setRefetch(refetch + 1);
      }
    });
  };
  return (
    <div className="event-container">
      <Col lg={12}>
        {events.length === 0 ? (
          <div className="d-flex justify-content-center align-items-center pb-5 pt-5">
            <InfinitySpin width="200" color="#5bcfc5" />
          </div>
        ) : (
          <>
            <FilteringTable
              data={events.data}
              columns={columns}
              title={"Events"}
            />
          </>
        )}
      </Col>
      <CreateEvent
        refetch={refetch}
        setRefetch={setRefetch}
        modalCentered={modalCentered}
        setModalCentered={setModalCentered}
      ></CreateEvent>
      <UpdateEvent
        refetch={refetch}
        setRefetch={setRefetch}
        updatedModal={updatedModal}
        setUpdatedModal={setUpdatedModal}
        selected={selected}
      ></UpdateEvent>
      <Button
        onClick={() => setModalCentered(true)}
        className="d-block d-block create-btn btn-xs"
      >
        Create Event
      </Button>
    </div>
  );
};

export default Events;
