/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import useScrollPosition from "use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import LogoutPage from "./Logout";
/// Image
import profile from "../../../images/profile/pic1.jpg";
import { useSelector } from "react-redux";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new Metismenu(this.$el);
  }
  componentWillUnmount() { }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = () => {
  const { iconHover, sidebarposition, headerposition, sidebarLayout } =
    useContext(ThemeContext);
  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);

    //sidebar icon Heart blast
    var handleheartBlast = document.querySelector(".heart");
    function heartBlast() {
      return handleheartBlast.classList.toggle("heart-blast");
    }
    handleheartBlast.addEventListener("click", heartBlast);
  }, []);
  let scrollPosition = useScrollPosition();
  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  //console.log(path)
  const user = useSelector((state) => state.auth.auth);
  // const user = store
  /// Active menu
  //console.log(user);
  let 
    users = ["users"],
    events = ["events"],
    eventSources = ["event-sources"],
    eventRegistrations = ["event-registrations"];
    
  return (
    <div
      className={`dlabnav ${iconHover} ${sidebarposition.value === "fixed" &&
          sidebarLayout.value === "horizontal" &&
          headerposition.value === "static"
          ? scrollPosition > 120
            ? "fixed"
            : ""
          : ""
        }`}
    >
      <PerfectScrollbar className="dlabnav-scroll">
        <MM className="metismenu" id="menu">
          <Dropdown as="li" className="nav-item dropdown header-profile">
            <Dropdown.Toggle
              variant=""
              as="a"
              className="nav-link i-false c-pointer"
              // href="#"
              role="button"
              data-toggle="dropdown"
            >
              <img src={profile} width={20} alt="" />
              <div className="header-info ms-3 heart">
                <span className="font-w600 ">
                  Hi,<b className="ms-1">{user?.profilename}</b>
                </span>
                <small className="text-end font-w400">{user?.email}</small>
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu
              align="right"
              className="mt-2 dropdown-menu dropdown-menu-end"
            >
              <Link to="#" className="dropdown-item ai-icon">
                <svg
                  id="icon-user1"
                  xmlns="http://www.w3.org/2000/svg"
                  className="text-primary"
                  width={18}
                  height={18}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                  <circle cx={12} cy={7} r={4} />
                </svg>
                <span className="ms-2">Profile </span>
              </Link>
              <Link className="dropdown-item ai-icon">
                <svg
                  id="icon-inbox"
                  xmlns="http://www.w3.org/2000/svg"
                  className="text-success"
                  width={18}
                  height={18}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
                  <polyline points="22,6 12,13 2,6" />
                </svg>
                <span className="ms-2">Inbox </span>
              </Link>
              <LogoutPage />
            </Dropdown.Menu>
          </Dropdown>
          {/* ------------------------------------super admin-------------------------------- */}
          {user?.role === "676dza2" && (
            <>
              <li className={`${users.includes(path) ? "mm-active" : ""}`}>
                <Link className="" to="/dashboard/users">
                  {/* <i className="fa-solid fa-user"></i> */}
                  <i class="fa fa-tachometer" aria-hidden="true"></i>
                  <span className="nav-text">Dashboard</span>
                </Link>
              </li>
              <li className={`${events.includes(path) ? "mm-active" : ""}`}>
                <Link className="" to="/dashboard/events">
                  {/* <i className="flaticon-022-copy"></i> */}
                  <i class="fa fa-calendar" aria-hidden="true"></i>

                  <span className="nav-text">Events</span>
                </Link>
              </li>
              <li
                className={`${eventSources.includes(path) ? "mm-active" : ""}`}
              >
                <Link className="" to="/dashboard/event-sources">
                  {/* <i className="flaticon-022-copy"></i> */}
                  <i class="fa fa-calendar" aria-hidden="true"></i>

                  <span className="nav-text">Event Sources</span>
                </Link>
              </li>
              <li
                className={`${eventRegistrations.includes(path) ? "mm-active" : ""
                  }`}
              >
                <Link className="" to="/dashboard/event-registrations">
                  {/* <i className="flaticon-022-copy"></i> */}
                  <i class="fa fa-calendar" aria-hidden="true"></i>

                  <span className="nav-text">Event Registrations</span>
                </Link>
              </li>
              
            </>
          )}

          {/* ------------------------------USER------------------------- */}
          {user?.role === "34fs3" && (
            <>
              <li className={`${events.includes(path) ? "mm-active" : ""}`}>
                <Link className="" to="/dashboard/events">
                  {/* <i className="flaticon-022-copy"></i> */}
                  <i class="fa fa-calendar" aria-hidden="true"></i>

                  <span className="nav-text">Events</span>
                </Link>
              </li>
              <li
                className={`${eventSources.includes(path) ? "mm-active" : ""}`}
              >
                <Link className="" to="/dashboard/event-sources">
                  {/* <i className="flaticon-022-copy"></i> */}
                  <i class="fa fa-calendar" aria-hidden="true"></i>

                  <span className="nav-text">Event Sources </span>
                </Link>
              </li>
              <li
                className={`${eventRegistrations.includes(path) ? "mm-active" : ""
                  }`}
              >
                <Link className="" to="/dashboard/event-registrations">
                  {/* <i className="flaticon-022-copy"></i> */}
                  <i class="fa fa-calendar" aria-hidden="true"></i>

                  <span className="nav-text">Event Registrations</span>
                </Link>
              </li>
              
            </>
          )}
        </MM>
        <div className="copyright">
          {/* <p>
            <strong>Dompet Payment Admin Dashboard</strong> © 2021 All Rights
            Reserved
          </p> */}
          <p className="fs-12">
             <span className=""></span>
          </p>
        </div>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
