import React, { useEffect, useState } from "react";
import { Col, Button } from "react-bootstrap";

import {  useNavigate } from "react-router-dom";
import { InfinitySpin } from "react-loader-spinner";
import ShowQR from "./ShowQR";
import CreateEventSource from "./CreateEventSource";
import UpdateEventSource from "./UpdateEventSource";
import { ColumnFilter } from "../../jsx/components/FilteringTable/ColumnFilter";
import FilteringTable from "../../jsx/components/FilteringTable/FilteringTable";
import { useDispatch, useSelector } from "react-redux";
import { deleteAsyncSource, fetchAllSources, getAllSources } from "../../store/features/sourceSlice";
const EventSources = () => {
  // const [sources, setSources] = useState([]);
  const sources = useSelector(getAllSources)
  const [eventSourceCreate, setEventSourceCreate] = useState(false);
  const [eventSourceUpdate, setEventSourceUpdate] = useState(false);
  const [selected, setSelected] = useState("");
  const [qrModal, setQrModal] = useState(false);
  const [qrEvent, setQrEvent] = useState('')
  const [qrId, setQrId] = useState("");
  const [refetch, setRefetch] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const columns = [
    {
      Header: "Event Source",
      Footer: "Event Source",
      accessor: "event.eventName",
      Filter: ColumnFilter,
    },
    {
      Header: "Source Name",
      Footer: "Source Name",
      accessor: "source",
      Filter: ColumnFilter,
    },
    {
      Header: "Generate QR Code",
      Footer: "Generate QR Code",
      accessor: "urlData",
      Filter: ColumnFilter,
      Cell: ({ row }) => (
        <button
          className="btn btn-primary btn-xs"
          onClick={() => {
            setQrId(`${row.original.urldata}${row.original._id}`);
            setQrModal(true);
            
          }}
        >
          Generate QR
        </button>
      ),
    },
    
    {
      Header: "Register",
      Footer: "Register",
      Filter: ColumnFilter,
      accessor: "",
      Cell: ({ row }) => (
        <button
          className="btn btn-primary btn-xs"
          onClick={() => navigate(`/registerbysource/${row.original._id}`)}
        >
          Register By Link
        </button>
      ),
    },
    {
      Header: "Status",
      Footer: "Status",
      Filter: ColumnFilter,
      accessor: "_id",
      Cell: ({ row }) => (
        <>
          <button
            href="#"
            className="btn btn-primary shadow btn-xs sharp me-1"
            onClick={() => {
              setSelected(row.original._id);
              setEventSourceUpdate(true);
            }}
          >
            <i className="fa fa-pencil"></i>
          </button>
          <button
            href="#"
            onClick={() => handleDeleteEvent(row.original._id)}
            className="btn btn-danger shadow btn-xs sharp ms-3"
          >
            <i className="fa fa-trash"></i>
          </button>
        </>
      ),
    },
  ];

  useEffect(() => {
    dispatch(fetchAllSources())
  }, [dispatch, refetch]);

  const handleDeleteEvent = async (id) => {
    dispatch(deleteAsyncSource(id)).then(res => {
      if(res.payload.type === 1){
        setRefetch(refetch +1)
      }
    })
  };
  //console.log(sources);
  return (
    <div className="event-container">
      <Col lg={12}>
        {sources?.length === 0 ? (
          <div className="d-flex justify-content-center align-items-center pb-5 pt-5">
            <InfinitySpin width="200" color="#5bcfc5" />
          </div>
        ) : (
          <>
            <FilteringTable
              data={sources?.data}
              columns={columns}
              title={"Event Sources"}
            />
          </>
        )}
      </Col>
      <CreateEventSource
        eventSourceCreate={eventSourceCreate}
        setEventSourceCreate={setEventSourceCreate}
        refetch={refetch}
        setRefetch={setRefetch}
      ></CreateEventSource>
      <UpdateEventSource
        setEventSourceUpdate={setEventSourceUpdate}
        eventSourceUpdate={eventSourceUpdate}
        selected={selected}
        refetch={refetch}
        setRefetch={setRefetch}
      ></UpdateEventSource>
      <ShowQR qrModal={qrModal} setQrModal={setQrModal} qrId={qrId}></ShowQR>
      <Button
        onClick={() => setEventSourceCreate(true)}
        className="d-block create-btn btn-xs"
      >
        Create Event Source
      </Button>
    </div>
  );
};

export default EventSources;
