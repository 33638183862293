import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { getAsyncProgramById, updateAsyncProgram } from "../../store/features/programSlice";

const UpdateEvent = ({
  updatedModal,
  setUpdatedModal,
  refetch,
  setRefetch,
  selected,
}) => {
  const [eventName, setEventName] = useState("");
  const [shortCode, setShortCode] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [comments, setComments] = useState("");
  const [eventBanner, setEventBanner] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAsyncProgramById(selected)).then((res) => {
      //console.log(res.data);
      if (res?.payload?.type === 1) {
        setEventName(res.payload.data[0].eventName);
        setShortCode(res.payload.data[0].shortCode);
        setStartDate(res.payload.data[0].startDate);
        setEndDate(res.payload.data[0].endDate);
        setComments(res.payload.data[0].comments);
      }
    });
  }, [dispatch, selected]);

  const loadImage = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL("image/jpeg");
        setEventBanner(dataURL);
      };
      img.src = event.target.result;
    };
    reader.readAsDataURL(file);
  };
  //console.log(eventBanner);
  const handleCreateEvent = async (e) => {
    e.preventDefault();
    if (shortCode.length > 4) {
      toast.error("Short Code must be less than 4 characters long!");
      return;
    }
    const date1 = new Date(startDate);
    const date2 = new Date(endDate);
    if (date1.getTime() > date2.getTime()) {
      toast.error("Start Date should be before End Date!");
      return;
    }
    const data = {
      _id: selected,
      eventName,
      shortCode,
      startDate,
      endDate,
      comments,
      eventBanner,
    };
    dispatch(updateAsyncProgram(data)).then(res => {
      if(res.payload.type === 1){
        setUpdatedModal(false)
        setRefetch(refetch +1)
      }
    })
  };
  return (
    <div>
      <Modal className="fade" show={updatedModal}>
        <Modal.Header>
          <Modal.Title>Update Event</Modal.Title>
          <Button
            onClick={() => setUpdatedModal(false)}
            variant=""
            className="btn-close"
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div className="col-xl-12 col-lg-12">
            <div className="basic-form">
              <form onSubmit={handleCreateEvent}>
                <div className="row">
                  <div className="form-group mb-3 col-md-6">
                    <label>Name Of the Event</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Name Of the Event"
                      value={eventName}
                      onChange={(e) => setEventName(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>Event Short Code</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Event Short Code"
                      value={shortCode}
                      onChange={(e) => setShortCode(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>Event Start Date</label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="text"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>Event End Date</label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="text"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group mb-3 col-md-12">
                    <label>Comments</label>
                    <textarea
                      type="text"
                      className="form-control"
                      placeholder="Comments"
                      value={comments}
                      onChange={(e) => setComments(e.target.value)}
                    />
                  </div>
                  <div className="input-group mb-3">
                    <div className="from-file w-100 d-flex justify-content-center">
                      <input
                        type="file"
                        className="form-file-input form-control d-block"
                        onChange={loadImage}
                      />
                    </div>
                  </div>
                </div>

                <button
                  type="submit"
                  className="btn btn-primary d-block mx-auto"
                >
                  Update
                </button>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UpdateEvent;
