import React, { useEffect, useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import axiosInstance from "../../services/AxiosInstance";
import { connect, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { addAsyncReg } from "../../store/features/registrationSlice";
import { useParams } from "react-router-dom";

const EventDetails = () => {
  const [selected, setSelected] = useState("");
  const [events, setEvents] = useState("");
  const [sources, setSources] = useState("");
  const [event, setEvent] = useState("");
  const [source, setSource] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [year, setYear] = useState("2023");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const dispatch = useDispatch();
  const { id } = useParams();
  useEffect(() => {
    axiosInstance.post("/events/readForDll").then((res) => {
      //console.log(res);
      if (res.data.type === 1) {
        setEvents(res.data.data);
        // setEvent(id);
      }
    });
  },[]);

  useEffect(() => {
    // if (event) {
      debugger
      axiosInstance
        .post("/source/readBySource", { sourceObjId: id })
        .then((res) => {
          if (res.data.type === 1) {
            debugger
            setEvent(res.data?.data[0]?.event?._id);
            setSources(res.data.data);
            setSource(id);
            
          }
        });
    // }
  },[]);

  // const handleEmail = (e) => {
  //   const regex = /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
  //   if (regex.test(e.target.value)) {
  //     setEmailError("");
  //     setEmail(e.target.value);
  //   } else {
  //     setEmailError("Invalid Email");
  //     setEmail("");
  //   }
  // };

  const handleCreateEvent = async (e) => {
    e.preventDefault();
    const regex = /^[0-9]*$/;
    const validation = /^[+]?[0-9]{0,3}[\\s]?[6-9][0-9]{9}$/;

    if (!regex.test(phone)) {
      e.target.value = phone.replace(/[^\d]/g, "");
    } else if (validation.test(phone)) {
      setPhoneError("");
      const data = {
        eventObjId: event,
        sourceObjId: source,
        regName: name,
        regCountry: selected,
        regYear: year,
        regPhone: phone,
        regEmail: email,
      };
      debugger
      // console.log(data);
      dispatch(addAsyncReg(data)).then((res) => {
        if (res.payload.type === 1) {
          debugger
          window.location.replace('https://orientscanner.checktestwebsite.com/checkBadgeCode/'+res.payload.data[0].badgeCode);
          // setShowCreateModal(false)
        }
      });
    } else {
      setPhoneError("Invalid Number");
    }
  };

  //console.log(events);
  return (
    <div className="container">
      <div className="col-xl-12 col-lg-12 mt-5">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Registration for Event</h4>
          </div>
          <div className="card-body">
            <div className="basic-form">
              <form onSubmit={handleCreateEvent}>
                <div className="row">
                  <div className="form-group mb-3 col-md-6">
                    <label>Select Event</label>
                    <select
                      defaultValue={"option"}
                      id="inputState"
                      className="form-control"
                      value={event}
                      // onChange={(e) => setEvent(e.target.value)}
                      aria-readonly
                      required
                    >
                      {events &&
                        events?.map((event) => (
                          <option key={event._id} value={event._id}>
                            {event.eventName}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className="form-group mb-3 col-md-6">
                    <label>Select Source</label>
                    <select
                      defaultValue={"option"}
                      id="inputState"
                      className="form-control"
                      required
                      value={source}
                      // onChange={(e) => setSource(e.target.value)}
                      aria-readonly
                    >
                      {sources &&
                        sources?.map((source) => (
                          <option key={source._id} value={source._id}>
                            {source.source}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className="form-group mb-3 col-md-6">
                    <label>Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="name"
                      required
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>

                  <div className="form-group mb-3 col-md-6">
                    <label>Email</label>
                    <input
                      type="text"
                      onChange={(e) => setEmail(e.target.value)}
                      className="form-control"
                      placeholder="Email"
                      value={email}
                      required
                    />
                    {emailError && <small>{emailError}</small>}
                  </div>

                  <div className="form-group mb-3 col-md-6">
                    <label>Phone</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Phone"
                      required
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                    {phoneError && <small>{phoneError}</small>}
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>Country</label>
                    <ReactFlagsSelect
                    countries={["AU", "GB", "US", "DE", "AU", "CA", "AE","FR","IE","SE","CH","SG"]}
                      selected={selected}
                      onSelect={(code) => setSelected(code)}
                      searchable
                      searchPlaceholder="Select a country"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group mb-3 col-md-6">
                    <label>Select Year</label>
                    <select
                      defaultValue={year}
                      id="inputState"
                      className="form-control"
                      onChange={(e) => setYear(e.target.value)}
                    >
                      <option>2023</option>
                      <option>2024</option>
                      <option>2025</option>
                      <option>Later</option>
                    </select>
                  </div>
                </div>

                <button
                  type="submit"
                  className="btn btn-primary d-block mx-auto"
                >
                  Create
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// export default EventDetails;
const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};
export default connect(mapStateToProps)(EventDetails);
