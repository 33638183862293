import React, { useEffect, useState } from "react";
import {  Button, Modal } from "react-bootstrap";

import axiosInstance from "../../services/AxiosInstance";
import QRCode from "react-qr-code";
import { useDispatch } from "react-redux";
import { getAsyncSourceById, updateAsyncSource } from "../../store/features/sourceSlice";

const UpdateEventSource = ({
  eventSourceUpdate,
  setEventSourceUpdate,
  selected,
  refetch,
  setRefetch,
}) => {
  const [events, setEvents] = useState([]);

  const [source, setSource] = useState("");
  const [eventObjId, setEventObjId] = useState("");
  const [url, setUrl] = useState('')
  const dispatch = useDispatch()
  useEffect(() => {
    axiosInstance.post("/events/read").then((res) => {
      if (res.data.type === 1) {
        setEvents(res.data.data);
      }
    });
  }, []);

  useEffect(() => {
    dispatch(getAsyncSourceById(selected)).then((res) => {
      //console.log(res.data);
      if (res?.payload?.type === 1) {
        setSource(res.payload.data[0].source);
        setEventObjId(res.payload.data[0].eventObjId);
        setUrl(res.payload.data[0].urldata)
      }
    });
  }, [dispatch, selected]);
  const handleCreateEvent = async (e) => {
    e.preventDefault();

    const data = {
      _id: selected,
      source,
      eventObjId,
    };
    dispatch(updateAsyncSource(data)).then(res => {
      if(res.payload.type === 1){
        setEventSourceUpdate(false)
        setRefetch(refetch +1)
      }
    })
  };
  
  return (
    <div>
      <Modal className="fade" show={eventSourceUpdate}>
        <Modal.Header>
          <Modal.Title>Create Event</Modal.Title>
          <Button
            onClick={() => setEventSourceUpdate(false)}
            variant=""
            className="btn-close"
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div className="col-xl-12 col-lg-12">
            <div className="basic-form">
              <form onSubmit={handleCreateEvent}>
                <div className="row">
                  <div className="form-group mb-3 col-md-12">
                    <label>Select Event</label>
                    <select
                      defaultValue={"option"}
                      className="form-control"
                      value={eventObjId}
                      onChange={(e) => setEventObjId(e.target.value)}
                    >
                      {events?.map((e) => (
                        <option key={e._id} value={e._id}>{e.eventName}</option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group mb-3 col-md-12">
                    <label>Source Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Source Name"
                      value={source}
                      onChange={(e) => setSource(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div>
                  <div className="d-flex justify-content-center">
                    <QRCode style={{height: "150px", width:"150px"}} value={url} />
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn btn-primary d-block mx-auto mt-3"
                > 
                  Update Source
                </button>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UpdateEventSource;
