import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axiosInstance from "../../services/AxiosInstance";
import { Button, Modal } from "react-bootstrap";
import ReactFlagsSelect from "react-flags-select";
import { useDispatch } from "react-redux";
import {
  fetchRegById,
  updateAsyncReg,
} from "../../store/features/registrationSlice";
const UpdateEventRegistration = ({
  updatedModal,
  setUpdatedModal,
  refetch,
  setRefetch,
  id,
}) => {
  const [selected, setSelected] = useState("");
  const [events, setEvents] = useState("");
  const [sources, setSources] = useState("");
  const [event, setEvent] = useState("");
  const [source, setSource] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [year, setYear] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    axiosInstance.post("/events/readForDll").then((res) => {
      if (res.data.type === 1) {
        setEvents(res.data.data);
      }
    });
  }, []);

  useEffect(() => {
    if (event) {
      axiosInstance
        .post("/source/readSourceByEvent", { eventObjId: event })
        .then((res) => {
          if (res.data.type === 1) {
            setSources(res.data.data);
            setSource(res.data?.data[0]?._id);
          }
        });
    }
  }, [event]);

  useEffect(() => {
    dispatch(fetchRegById(id)).then((res) => {
      //console.log(res.data);
      if (res?.payload?.type === 1) {
        setName(res.payload.data[0].regName);
        setEmail(res.payload.data[0].regEmail);
        setPhone(res.payload.data[0].regPhone);
        setSelected(res.payload.data[0].regCountry);
        setYear(res.payload.data[0].regYear);
        setEvent(res.payload.data[0].event._id);
        setSource(res.payload.data[0].source._id);
      }
    });
  }, [dispatch, id]);

  const handleCreateEvent = async (e) => {
    e.preventDefault();
    // const phone = e.target.phone.value;
    const regex = /^[0-9]*$/;
    const validation = /^[+]?[0-9]{0,3}[\\s]?[6-9][0-9]{9}$/;

    if (!regex.test(phone)) {
      // e.target.value = phone.replace(/[^\d]/g, "");
    } else if (validation.test(phone)) {
      // setPhone(phone);
      setPhoneError("");
      const data = {
        _id: id,
        eventObjId: event,
        sourceObjId: source,
        regName: name,
        regCountry: selected,
        regYear: year,
        regPhone: phone,
        regEmail: email,
      };
      //console.log(data);
      dispatch(updateAsyncReg(data)).then((res) => {
        if (res.payload.type === 1) {
          setUpdatedModal(false);
          setRefetch(refetch + 1);
        }
      });
    } else {
      setPhoneError("Invalid Number");
    }
  };
  const handleEvent = async (e) => {
    setEvent(e.target.value);
    await axiosInstance
      .post("/source/readSourceByEvent", { eventObjId: e.target.value })
      .then((res) => {
        if (res.data.type === 1) {
          setSources(res.data.data);
        }
      });
  };
  //console.log(phoneError);
  return (
    <div>
      <Modal className="fade" show={updatedModal}>
        <Modal.Header>
          <Modal.Title>Update Event</Modal.Title>
          <Button
            onClick={() => setUpdatedModal(false)}
            variant=""
            className="btn-close"
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div className="col-xl-12 col-lg-12">
            <div className="basic-form">
              <form onSubmit={handleCreateEvent}>
                <div className="row">
                  <div className="form-group mb-3 col-md-6">
                    <label>Select Event</label>
                    <select
                      defaultValue={"option"}
                      id="inputState"
                      className="form-control"
                      value={event}
                      onChange={handleEvent}
                      required
                      
                    >
                      {events &&
                        events?.map((event) => (
                          <option key={event._id} value={event._id}>
                            {event.eventName}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className="form-group mb-3 col-md-6">
                    <label>Select Source</label>
                    <select
                      defaultValue={"option"}
                      id="inputState"
                      className="form-control"
                      required
                      value={source}
                      // onChange={(e) => setSource(e.target.value)}
                      aria-readonly
                    >
                      {sources &&
                        sources?.map((source) => (
                          <option key={source._id} value={source._id}>
                            {source.source}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className="form-group mb-3 col-md-6">
                    <label>Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="name"
                      required
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>

                  <div className="form-group mb-3 col-md-6">
                    <label>Email</label>
                    <input
                      type="email"
                      // onChange={(e) => handleEmail(e)}
                      className="form-control"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                    {emailError && <small>{emailError}</small>}
                  </div>

                  <div className="form-group mb-3 col-md-6">
                    <label>Phone</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Phone"
                      required
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                    {phoneError && <small>{phoneError}</small>}
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>Country</label>
                    <ReactFlagsSelect
                    countries={["AU", "GB", "US", "DE", "AU", "CA", "AE","FR","IE","SE","CH","SG"]}
                      selected={selected}
                      onSelect={(code) => setSelected(code)}
                      searchable
                      searchPlaceholder="Select a country"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group mb-3 col-md-6">
                    <label>Select Year</label>
                    <select
                      defaultValue={year}
                      id="inputState"
                      className="form-control"
                      onChange={(e) => setYear(e.target.value)}
                    >
                      <option>2023</option>
                      <option>2024</option>
                      <option>2025</option>
                      <option>Later</option>
                    </select>
                  </div>
                </div>

                <button
                  type="submit"
                  className="btn btn-primary d-block mx-auto"
                >
                  Save
                </button>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UpdateEventRegistration;
