import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const RequireAuth = ({children}) => {
    const user = JSON.parse(localStorage.getItem('userDetails'))
    let location = useLocation();
    // if(loading){
    //     return <Loading></Loading>;
    // }
    if (!user) {
        return <Navigate to="/" state={{ from: location }} replace />;
    }

  return children;
};

export default RequireAuth;