import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../services/AxiosInstance";
import { toast } from "react-toastify";

export const fetchAllPrograms = createAsyncThunk(
  "/programs/fetchAllPrograms",
  async () => {
    const response = await axiosInstance.post("/events/read");
    //console.log(response);
    return response.data;
  }
);

export const addAsyncProgram = createAsyncThunk(
  "/programs/addAsyncProgram",
  async (data) => {
    // //console.log(payload)
    const response = await axiosInstance.post(`/events/save`, data);
    //console.log(response);
    if (response.data.type === 1) {
      toast.info("Event Created Successfully!");
    } else if (response.data.type === 2) {
      toast.error(response.data.message);
    }

    return response.data;
  }
);

export const deleteAsyncProgram = createAsyncThunk(
  "/programs/deleteAsyncProgram",
  async (id) => {
    // //console.log(payload)
    const response = await axiosInstance.post(`/events/delete`, { _id: id });
    //console.log(response);
    if (response.data.type === 1) {
      toast.warning("Event Deleted Successfully!");
    } else if (response.data.type === 2) {
      toast.error("Failed to Delete the Event!");
      // setRefetch(refetch + 1);
    }
    return response.data;
  }
);

export const updateAsyncProgram = createAsyncThunk(
  "/programs/updateAsyncProgram",
  async (data) => {
    // //console.log(payload)
    const response = await axiosInstance.post(`/events/update`, data);
    //console.log(response);
    if (response.data.type === 1) {
      toast.info("Event Updated Successfully!");
    } else {
      toast.error("Failed to update the Event!");
    }
    return response.data;
  }
);

export const getAsyncProgramById = createAsyncThunk(
  "/programs/getAsyncProgramById",
  async (selected) => {
    // //console.log(payload)
    const response = await axiosInstance.post("/events/read", {
      _id: selected,
    });
    //console.log(response);
    return response.data;
  }
);
const initialState = {
  allPrograms: [],
  addProgram: [],
  programById: [],
  deleteProgram: [],
  updateProgram: [],
};

const programSlice = createSlice({
  name: "programs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllPrograms.pending, () => {
      //console.log("pending");
    });
    builder.addCase(fetchAllPrograms.fulfilled, (state, { payload }) => {
      return { ...state, allPrograms: payload };
    });
    builder.addCase(addAsyncProgram.fulfilled, (state, { payload }) => {
      return { ...state, addProgram: payload };
    });
    builder.addCase(fetchAllPrograms.rejected, () => {
      //console.log("rejected");
    });
    builder.addCase(deleteAsyncProgram.pending, () => {
      //console.log("pending");
    });
    builder.addCase(deleteAsyncProgram.fulfilled, (state, { payload }) => {
      return { ...state, deleteEvent: payload };
    });
    builder.addCase(deleteAsyncProgram.rejected, () => {
      //console.log("rejected");
    });
    builder.addCase(updateAsyncProgram.pending, () => {
      //console.log("pending");
    });
    builder.addCase(updateAsyncProgram.fulfilled, (state, { payload }) => {
      return { ...state, updateEvent: payload };
    });
    builder.addCase(updateAsyncProgram.rejected, () => {
      //console.log("rejected");
    });
    builder.addCase(getAsyncProgramById.pending, () => {
      //console.log("pending");
    });
    builder.addCase(getAsyncProgramById.fulfilled, (state, { payload }) => {
      return { ...state, eventById: payload };
    });
    builder.addCase(getAsyncProgramById.rejected, () => {
      //console.log("rejected");
    });
  },
});
export const getAllPrograms = (state) => state.program.allPrograms;
export const getProgramById = (state) => state.program.programById;

export default programSlice.reducer;
